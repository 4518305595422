import { useMemo } from 'react';

import useTranslation from 'next-translate/useTranslation';

import type { SxProps } from '@mui/material';
import { Chip, Typography } from '@mui/material';

import useCheckDevice from 'hooks/useCheckDevice';

const MembersOnlyChip = ({
  isSmall = false,
  sx,
}: {
  isSmall?: boolean;
  sx?: SxProps;
}) => {
  const { t } = useTranslation('common');
  const { isMobile } = useCheckDevice();

  // mobile && small - padding 1px 4px / height 20px
  // mobile && large - padding 1px 6px / height 20px
  // desktop && small - padding 1px 6px / height 20px
  // desktop && large - padding 1px 6px / height 22px

  // small - 경기상세, 동영상 상세, 재생목록상세페이지 컨텐츠 리스트, 커뮤니티
  // large - 리스트,

  const styles = useMemo(() => {
    if (isMobile) {
      return {
        height: '20px',
        padding: isSmall ? '1px 4px' : '1px 6px',
      };
    }

    return {
      height: isSmall ? '20px' : '22px',
      padding: '1px 6px',
    };
  }, [isMobile, isSmall]);

  return (
    <Chip
      label={
        <Typography
          variant={!isMobile && !isSmall ? 'subtitle2Bold' : 'subtitle3Bold'}
        >
          {t('visibility.membersOnly')}
        </Typography>
      }
      sx={{
        textAlign: 'center',
        borderRadius: '3px',
        background:
          'linear-gradient(114.64deg, #37C556 13.75%, #15B3B3 82.09%)',
        ...styles,

        '>.MuiChip-label': { p: 0, color: 'white' },

        ...sx,
      }}
    />
  );
};

export default MembersOnlyChip;
