import { useState } from 'react';

import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useRouter } from 'next/router';

import DateRangeIcon from '@mui/icons-material/DateRange';
import ShareIcon from '@mui/icons-material/Share';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getMembershipList } from 'api/channelMembership';

import EventChip from 'components/atoms/EventChip';
import MembersOnlyChip from 'components/commons/MembersOnlyChip';
import MembersOnlyDialog from 'components/dialogs/MembersOnlyDialog';
import MembershipSignUpDialog from 'components/dialogs/MembershipSignUpDialog';

import useActiveAuth from 'hooks/useActiveAuth';
import useCheckDevice from 'hooks/useCheckDevice';
import useToggle from 'hooks/useToggle';

import type { EventListData } from 'types/event';

import { dayFormatter } from 'utils/dayFormatter';
import { eventThumbnailPicker } from 'utils/defaultThumbnailPicker';

const NotAllowedEventCard = ({
  eventDetailData,
  usedPage,
}: {
  eventDetailData: EventListData;
  usedPage: 'main' | 'channel' | 'event' | 'search' | 'channelHome';
}) => {
  const { t } = useTranslation('event');
  const { locale } = useRouter();
  const { mode } = useTheme();
  const { isProfileLoadedUser } = useActiveAuth();
  const { isPc, isMobile } = useCheckDevice();
  const {
    isOpen: isOnlyMembersDialogOpen,
    open: openOnlyMembersDialog,
    close: closeOnlyMembersDialog,
  } = useToggle();
  const {
    isOpen: isMembershipSignUpDialogOpen,
    open: openMembershipSignUpDialog,
    close: closeMembershipSignUpDialog,
  } = useToggle();

  const {
    status,
    thumbnailName,
    thumbnailUrl: initThumbnailUrl,
    title,
    from,
    to,
    sportType,
    registerStart,
    registerEnd,
    user: { channel },
  } = eventDetailData;

  const [thumbnailUrl, setThumbnailUrl] = useState<string>(
    initThumbnailUrl || eventThumbnailPicker(sportType || 'universal'),
  );

  const eventStatusColors = {
    register: 'primaryColor.500',
    scheduled: 'secondaryColor.2',
    progress: '#BF0C0E',
  };

  const calculateDday = (status: 'register' | 'scheduled') => {
    const TODAY_TIME = new Date().getTime();
    let timeGap = 0;
    if (status === 'register' && registerEnd) {
      const registerEndTime = new Date(registerEnd).getTime();
      timeGap = registerEndTime - TODAY_TIME;
    }

    if (status === 'scheduled') {
      if (registerStart) {
        const registerStartTime = new Date(registerStart).getTime();
        timeGap = registerStartTime - TODAY_TIME;
      }
      if (timeGap <= 0 && from) {
        const fromTime = new Date(from).getTime();
        timeGap = fromTime - TODAY_TIME;
      }
    }

    const dDay = Math.ceil(timeGap / (1000 * 60 * 60 * 24));
    return `D-${dDay}`;
  };

  const { data: membershipList } = useQuery({
    queryKey: ['membershipPlanList', channel.id],
    queryFn: () => getMembershipList(channel.id),
    staleTime: 1000 * 60 * 5,
    enabled: isProfileLoadedUser,
  });

  return (
    <Box
      onClick={openOnlyMembersDialog}
      sx={{
        borderRadius: '6px',
        border: '1px solid',
        borderColor: 'divider',
        cursor: 'pointer',
        overflow: 'hidden',
        minWidth:
          usedPage === 'main'
            ? { xs: '210px', sm: '250px', lg: '300px' }
            : usedPage === 'channelHome'
              ? { xs: '225px', sm: '300px' }
              : '100%',
        width: '100%',
        maxWidth:
          usedPage === 'channelHome' ? { xs: '225px', sm: '300px' } : 'none',
        transition: 'transform .3s, box-shadow .3s',
        scrollSnapAlign: 'start',

        ...(isPc && {
          ':hover': {
            transform: 'translateY(-8px)',
            boxShadow:
              mode === 'dark'
                ? '0px 8px 16px rgba(120, 120, 120, 0.25)'
                : '0px 8px 16px rgba(0, 0, 0, 0.25)',
          },
        }),
      }}
    >
      <Box sx={{ position: 'relative' }}>
        {(status === 'register' && registerEnd) ||
        (status === 'scheduled' && from) ? (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: { xs: 'auto', sm: '20px' },
              left: { xs: '20px', sm: 'auto' },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: { xs: '36px', sm: '50px' },
              height: { xs: '28px', sm: '37px' },
              bgcolor: eventStatusColors[status],
              filter: 'drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25))',
              zIndex: 2,
              '&:before': {
                position: 'absolute',
                top: { xs: '28px', sm: '37px' },
                left: 0,
                width: 0,
                height: 0,
                content: "''",
                borderTopWidth: { xs: '12px', sm: '18px' },
                borderTopStyle: 'solid',
                borderTopColor: eventStatusColors[status],
                borderLeft: {
                  xs: '18px solid transparent',
                  sm: '25px solid transparent',
                },
                borderRight: {
                  xs: '18px solid transparent',
                  sm: '25px solid transparent',
                },
              },
            }}
          >
            <Typography
              variant="chip"
              sx={{
                fontWeight: 700,
                color: 'white',
                fontSize: { xs: '10px', sm: 'inherit' },
                pt: { xs: '6px', sm: '9px' },
              }}
            >
              {calculateDday(status)}
            </Typography>
          </Box>
        ) : null}

        <Box
          sx={{
            position: 'relative',
            aspectRatio: '3 / 4',
            borderRadius: '6px',

            '&:before': {
              content: "''",
              zIndex: 1,
              position: 'absolute',
              bottom: '-4px',
              left: 0,
              right: 0,
              height: 'calc(100% + 4px)',
              borderRadius: '6px',
              background: 'rgba(0, 0, 0, 0.6)',
            },
          }}
        >
          <Image
            src={thumbnailUrl}
            layout="fill"
            placeholder="blur"
            blurDataURL="data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8bwEAAjwBOdoVrI8AAAAASUVORK5CYII"
            objectFit="cover"
            alt={thumbnailName ?? ''}
            style={{ borderRadius: '6px' }}
            onError={() => {
              const altThumbnailUrl = eventThumbnailPicker(
                sportType || 'universal',
              );
              setThumbnailUrl(altThumbnailUrl);
            }}
          />

          <MembersOnlyChip
            sx={{
              position: 'absolute',
              top: { xs: '8px', sm: '24px' },
              left: { xs: '8px', sm: '24px' },
              zIndex: 1,
            }}
          />
        </Box>

        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 2,
            pl: { xs: '14px', sm: '20px', lg: 3 },
            pr: { xs: 0, sm: 2, lg: 2 },
            pb: { xs: 1, sm: 2, lg: '20px' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: { xs: 0.5, sm: 1 },
            }}
          >
            <EventChip type="status" value={status} customSx={{ mr: 0.5 }} />
            {sportType !== null ? (
              <EventChip type="sportType" value={sportType} />
            ) : null}
          </Box>

          <Typography
            variant="h4Bold"
            sx={{
              mb: { xs: '4px', sm: 1 },
              pr: { xs: 1, sm: 0 },
              display: '-webkit-box',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflowWrap: 'anywhere',
              fontSize: { xs: '14px', sm: '20px' },
              color: 'white',
            }}
          >
            {title}
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <DateRangeIcon
              sx={{
                display: { xs: 'none', sm: 'block' },
                mr: 1,
                width: '18px',
                height: '18px',
                color: 'deprecatedGray.60',
              }}
            />
            {from ? (
              <Typography
                variant="body2"
                sx={{
                  color: 'deprecatedGray.60',
                  fontSize: {
                    xs: usedPage === 'main' ? '11px' : '10px',
                    sm: '14px',
                  },
                }}
              >
                {`${dayFormatter(from, 'YYYY.MM.DD ', {
                  locale,
                  isZuluTime: true,
                })} ${
                  to !== null
                    ? `~ ${dayFormatter(to, 'YYYY.MM.DD ', {
                        locale,
                        isZuluTime: true,
                      })}`
                    : ''
                } `}
              </Typography>
            ) : (
              <Typography variant="body3" sx={{ color: 'gray.700' }}>
                {t('errorMsg.emptySchedule')}
              </Typography>
            )}
          </Box>
        </Box>

        <IconButton
          disableTouchRipple
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          sx={{
            position: 'absolute',
            zIndex: 10,
            right: { xs: '4px', sm: '8px' },
            ...(isMobile ? { top: '4px' } : { bottom: '16px' }),
            color: 'gray.500',
          }}
        >
          <ShareIcon />
        </IconButton>
      </Box>

      {isOnlyMembersDialogOpen ? (
        <MembersOnlyDialog
          open={isOnlyMembersDialogOpen}
          onClose={closeOnlyMembersDialog}
          onAction={openMembershipSignUpDialog}
        />
      ) : null}

      {isMembershipSignUpDialogOpen && membershipList ? (
        <MembershipSignUpDialog
          isOpen={isMembershipSignUpDialogOpen}
          close={closeMembershipSignUpDialog}
          membershipList={membershipList}
        />
      ) : null}
    </Box>
  );
};

export default NotAllowedEventCard;
