import { useCallback } from 'react';

import useAxios from 'hooks/useAxios';

import type {
  JoinedMembershipPageType,
  JoinedMembershipType,
  MemberListParamsType,
  MemberPageType,
  MembershipSettingType,
} from 'types/channelMembership';

const useChannelMembershipApi = () => {
  const { studioAuthAxiosV1 } = useAxios();

  const createMembership = useCallback(
    async ({ data }: { data: MembershipSettingType }) =>
      await studioAuthAxiosV1({
        method: 'POST',
        url: '/membership/plans',
        data,
      }),
    [studioAuthAxiosV1],
  );

  const getMemberList = useCallback(
    async (params: MemberListParamsType) =>
      await studioAuthAxiosV1<MemberPageType>({
        method: 'GET',
        url: '/membership/membership-members',
        params,
      }),
    [studioAuthAxiosV1],
  );

  const updateMembership = useCallback(
    async ({
      membershipId,
      data,
    }: {
      membershipId: number;
      data: MembershipSettingType;
    }) =>
      await studioAuthAxiosV1({
        method: 'PATCH',
        url: `/membership/plans/${membershipId}`,
        data,
      }),
    [studioAuthAxiosV1],
  );

  const deleteMembership = useCallback(
    async ({ membershipId }: { membershipId: number }) =>
      await studioAuthAxiosV1({
        method: 'DELETE',
        url: `/membership/plans/${membershipId}`,
      }),
    [studioAuthAxiosV1],
  );

  const joinMembership = useCallback(
    async (data: {
      applyChannelId: number;
      membershipPlanId: number;
      answer?: string;
    }) =>
      await studioAuthAxiosV1({
        method: 'POST',
        url: '/membership/join-application',
        data,
      }),
    [studioAuthAxiosV1],
  );

  const approveMembershipMembers = useCallback(
    async (approveMemberIdList: number[]) =>
      await studioAuthAxiosV1({
        method: 'PATCH',
        url: '/membership/membership-members',
        data: {
          approveMembershipMemberIds: approveMemberIdList,
        },
      }),
    [studioAuthAxiosV1],
  );

  const rejectMembershipMembers = useCallback(
    async (rejectMemberIdList: number[]) =>
      await studioAuthAxiosV1({
        method: 'PATCH',
        url: '/membership/membership-members',
        data: {
          rejectMembershipMemberIds: rejectMemberIdList,
        },
      }),
    [studioAuthAxiosV1],
  );

  const kickMembershipMembers = useCallback(
    async ({
      deleteMemberIdList,
      reason,
    }: {
      deleteMemberIdList: number[];
      reason: string;
    }) =>
      await studioAuthAxiosV1({
        method: 'DELETE',
        url: '/membership/membership-members',
        data: {
          deleteMembershipMemberIds: deleteMemberIdList,
          reason,
        },
      }),
    [studioAuthAxiosV1],
  );

  const getJoinedMembershipList = useCallback(
    async ({
      ...params
    }: {
      limit: number;
      offset: number;
      active: boolean;
      approved?: boolean;
      orderByCreatedAt?: string;
      orderByUpdatedAt?: string;
    }) =>
      await studioAuthAxiosV1<JoinedMembershipPageType>({
        method: 'GET',
        url: '/membership/my-memberships',
        params,
      }),
    [studioAuthAxiosV1],
  );

  const getJoinedMembership = useCallback(
    async (channelId: number) =>
      await studioAuthAxiosV1<JoinedMembershipType>({
        method: 'GET',
        url: `/membership/my-memberships/${channelId}`,
      }),
    [studioAuthAxiosV1],
  );

  const stopJoinedMembership = useCallback(
    async (membershipMemberId: number) =>
      await studioAuthAxiosV1({
        method: 'DELETE',
        url: `/membership/my-memberships/${membershipMemberId}`,
      }),
    [studioAuthAxiosV1],
  );

  const stopChannelMembership = useCallback(
    async () =>
      await studioAuthAxiosV1({
        method: 'DELETE',
        url: '/membership',
      }),
    [studioAuthAxiosV1],
  );

  return {
    createMembership,
    getMemberList,
    updateMembership,
    deleteMembership,
    joinMembership,
    approveMembershipMembers,
    rejectMembershipMembers,
    kickMembershipMembers,
    getJoinedMembershipList,
    getJoinedMembership,
    stopJoinedMembership,
    stopChannelMembership,
  };
};

export default useChannelMembershipApi;
