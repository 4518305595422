import type { EventStatus, EventType } from 'types/event';
import type { SportType } from 'types/scoreboardData';
import type { VisibilityType } from 'types/visibility';

export const getEventQueries = (option: {
  q?: string;
  uidsFilter?: string[];
  eventTypesFilter?: EventType[];
  sportTypesFilter?: SportType[];
  statusesFilter?: EventStatus[];
  orderByCountry: string;
  orderByLanguage: string;
  orderByViews?: 'ASC' | 'DESC';
  orderByCreatedAt?: 'ASC' | 'DESC';
  useOrderByDeadlineImminent?: boolean;
  useOrderByAdminPick?: boolean;
  limit?: number;
  offset?: number;
}) => {
  const {
    q,
    uidsFilter,
    eventTypesFilter,
    sportTypesFilter,
    statusesFilter,
    orderByCountry,
    orderByLanguage,
    orderByViews,
    orderByCreatedAt,
    useOrderByDeadlineImminent,
    useOrderByAdminPick,
    limit,
    offset,
  } = option;

  // 필수 옵션으로 기본값 지정해주기
  const defaultOptions = {
    orderByCountry: orderByCountry || 'KR',
    orderByLanguage: orderByLanguage || 'ko',
    limit: limit || 12,
    offset: offset || 0,
  };

  // 단일값 옵션
  const commonOptions = {
    q,
    orderByViews,
    orderByCreatedAt,
    useOrderByDeadlineImminent,
    useOrderByAdminPick,
  };

  // 타입이 배열인 옵션 array=a,b,c 형식으로 넘겨야한다.
  const arrayOptions = {
    uidsFilter,
    eventTypesFilter,
    sportTypesFilter,
    statusesFilter,
  };

  const commonParams: { [key: string]: string | number | boolean | undefined } =
    {
      ...defaultOptions,
      ...commonOptions,
    };

  const arrayParams: {
    [key: string]:
      | string[]
      | EventType[]
      | SportType[]
      | EventStatus[]
      | undefined;
  } = arrayOptions;

  const query = [
    ...Object.keys(commonParams)
      .filter(
        (key) => commonParams[key] !== undefined && commonParams[key] !== null,
      )
      .map((key) => `${key}=${commonParams[key]}`),
    ...Object.keys(arrayParams)
      .filter(
        (key) =>
          arrayParams[key] !== undefined &&
          arrayParams[key] !== null &&
          arrayParams[key]?.length !== 0,
      )
      .map(
        (key) =>
          `${key}=` +
          (arrayParams[key] || []).map((value) => `${value}`).join(','),
      ),
  ].join('&');

  return `?${query}`;
};

export const getMatchQueries = (option: {
  category?: 'all' | 'interest' | 'live';
  uid?: string;
  channelId?: number;
  country?: string | null;
  date?: string;
  disclosure?: VisibilityType | null;
  filters?: 'follow' | 'reported';
  follow?: boolean;
  from?: string;
  hashtags?: string;
  offset: number;
  period?: string;
  q?: string;
  sorts?: 'staffPick' | 'view' | 'like' | 'createdAt' | 'updatedAt';
  sportType?: string;
  status?: string;
  timezone?: string;
  to?: string;
  language?: string;
  limit?: number;
}) => {
  const {
    category,
    channelId,
    country,
    date,
    disclosure,
    filters,
    follow,
    from,
    hashtags,
    offset,
    period,
    q,
    sorts,
    sportType,
    status,
    timezone,
    to,
    uid,
    language,
    limit,
  } = option;

  // 필수 옵션으로 기본값 지정해주기
  const defaultOptions = {
    country: country || 'KR',
    language: language || 'ko',
    limit: limit || 12,
    offset: offset || 0,
  };

  // 단일값 옵션 (중복되면 안됩니다.)
  const commonOptions = {
    category,
    channelId,
    date,
    disclosure,
    filters,
    follow,
    from,
    hashtags,
    period,
    q,
    sorts,
    sportType,
    status,
    timezone,
    to,
    uid,
  };

  const commonParams: {
    [key: string]: string | number | boolean | null | undefined;
  } = {
    ...defaultOptions,
    ...commonOptions,
  };

  const query = Object.keys(commonParams)
    .filter(
      (key) => commonParams[key] !== undefined && commonParams[key] !== null,
    )
    .map((key) => `${key}=${commonParams[key]}`)
    .join('&');

  return `?${query}`;
};
