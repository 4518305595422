import { studioPublicAxiosV1withAPIKey } from 'lib/axios';

import type { MembershipType } from 'types/channelMembership';

export const getMembershipList = async (channelId: number) =>
  await studioPublicAxiosV1withAPIKey<MembershipType[]>({
    method: 'GET',
    url: `/membership/plans`,
    params: {
      channelId,
    },
  });
