import useTranslation from 'next-translate/useTranslation';

import CommonDialog from '../CommonDialog';

const MembersOnlyDialog = ({
  open,
  onClose,
  onAction,
}: {
  open: boolean;
  onClose: () => void;
  onAction: () => void;
}) => {
  const { t } = useTranslation('dialogs');

  return (
    <CommonDialog.MessageComponent
      title={t('membersOnlyDialog.title')}
      description={t('membersOnlyDialog.description')}
      open={open}
      actionText={t('membersOnlyDialog.join')}
      onClose={onClose}
      onAction={() => {
        onAction();
        onClose();
      }}
    />
  );
};

export default MembersOnlyDialog;
